import React from "react"
import { Link } from "gatsby"
import Seo from "./SEO"
import logo from "../images/logo.png"

const Layout = ({ children }) => {
  return (
    <>
      <Seo />
      <div className="flex flex-col min-h-screen bg-gray-200">
        <header className="p-4 bg-green-500 text-red-700 font-semibold">
          <Link to="/" className="max-w-4xl mx-auto flex items-center">
            <img src={logo} alt="logo" className="h-12" />
          </Link>
        </header>
        {children}
        <footer className="py-2 text-center text-gray-600 text-xs">
          &copy; GIA 2020 SL
        </footer>
      </div>
    </>
  )
}

export default Layout
